export const environment = {
  production: false,
  cacheLocation : "localStorage",
  auth: {
   domain: "dev-new-cts.auth0.com",
clientId:"0JtjqaeKfrIXlsRNiqc5jjMkk12tMNwI",
redirect_uri: 'https://cmt-test.savvas.com/cm-web/',
authApi : "https://dev-new-cts.auth0.com/api/v2/users-by-email?email=",
  },
  serverUrl : 'https://cmt-test.savvas.com/cm-web/'
};
