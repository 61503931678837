import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { ConfirmationPopupService } from '../../services/confirmation-popup/confirmation-popup.service';
import * as _ from "underscore";
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UtilService } from '../../services/common/util.service';
import { any, result } from 'underscore';
import { PreloadDataService } from 'src/app/services/common/preload-data.service';

@Component({
  selector: 'app-intermediary-statements',
  templateUrl: './intermediary-statements.component.html',
  styleUrls: ['./intermediary-statements.component.scss']
})
export class IntermediaryStatementsComponent implements OnInit {
  // public controllerScope = scope.$parent.$parent; ///This needs to be changed on doing intermediary page
  public controllerScope: any;
  public createdBy: string = "";
  public intermediaryCodeSearchEnabled: boolean = false;
  //Model for storing searched text
  public textParamValue: any = [];
  public codeParamValue: any = [];
  //Model for fields like check-boxes & input
  public searchModel: any = {
    'type': [
      { schema: 'INTERMEDIARY_TYPE_STFK_SCHEMA', type: 'INTERMEDIARY_TYPE_STFK', value: 'Skills, Tools and Factual Knowledge', shortValue: "STFk", checked: true },
      { schema: 'INTERMEDIARY_TYPE_CU_SCHEMA', type: 'INTERMEDIARY_TYPE_CU', value: 'Conceptual Understanding', shortValue: "CU", checked: true },
      { schema: 'INTERMEDIARY_TYPE_P_SCHEMA', type: 'INTERMEDIARY_TYPE_P', value: 'Process', shortValue: "P", checked: true }
    ],
    'text': ''
  }
  //Pagination related variables
  public pagination: any = {
    "pageSize": 100,
    "totalRecords": 0,
    "pageNum": 1,
  };

  public searchListItem: any = [];
  public knowledgeCartDirty = false;
  public recordsUnavailable: boolean = false;
  public statementLoading: boolean = false;
  public breakOnWord: boolean = true;
  public fromPage: any;
  public subjectIntermediaryList: any;
  public subjectMappingLength: any;
  public subjectBeanList: any;
  public rootIntermediaryId: any;
  public statementTabName: any;
  public subjectlength: any;
  public selectedList: any = {
    subject: {}
  };
  public knowledgeCartScope: any = {
    knowledgeCartList: new Array()
  }
  public newCartItemCount = 0;
  public selectedSubject: any;
  public typeCheckBoxValues: any;

  constructor(
    private actRoute: ActivatedRoute,
    private route: Router,
    private httpRestClient: HttpRestClientService,
    public globalVar: GlobalVariablesService,
    private modalService: BsModalService,
    private utilService: UtilService,
    private confirmationPopup: ConfirmationPopupService,
    private preloadData: PreloadDataService
  ) { }

  ngOnInit(): void {
    this.initFunction();
    this.loadMore();
    this.preloadData.backTODefault.subscribe((data: any) => {
      this.searchIntermediary(false);
    })
    this.preloadData.intialItemAddedToCard.subscribe((data: any) => {
      console.log("card", data);
      console.log("cardfff", this.searchListItem);
      console.log()
      this.searchListItem?.forEach((item: any) => {
        data.forEach((searchItem: any) => {
          if (item.id == searchItem.id) {
            item.addedToCart = true;
          }
        })
      })
      this.knowledgeCartScope.knowledgeCartList = [];
      this.newCartItemCount = 0;
    })

    this.preloadData.removeData.subscribe((data:any)=>{
      this.searchListItem?.forEach((item: any) => {
          if (item.id == data?.data.id) {
            item.addedToCart = false;
          }
      })
      let index = this.knowledgeCartScope.knowledgeCartList.findIndex((item:any)=>{return data?.data.id==item.id} );
      this.knowledgeCartScope.knowledgeCartList.splice(index, 1);
      this.newCartItemCount = data.count;
    })
  }

  public initFunction = () => {
    if (!this.globalVar.filterSubjectsHomePage && !this.globalVar.filterCountriesHomePage) {
      this.globalVar.activeMainMenu = 'view';
      this.route.navigate(['/view/approved']);
      return;
    }

    this.fromPage = _.clone(this.globalVar.isFromHome);
    this.globalVar.knowledgeCartDirty = false;
    this.subjectIntermediaryList = this.globalVar.languageString.resources.subjectMappingsMetadata;

    if (this.globalVar.isFromHome == 'homePage' && this.globalVar.filterSubjectsHomePage) {
      this.subjectBeanList = this.getModifiedFiltSubjHome(this.globalVar.filterSubjectsHomePage);
    } else {
      this.subjectBeanList = this.globalVar.intermediaryPreSelectedDropdown.intermediaryMappingBean.intermediaryMappingDataBean.subjectBeanList;
    }

    this.subjectMappingLength = this.subjectIntermediaryList.length;

    for (var i = 0; i < this.subjectMappingLength; i++) {
      if (this.globalVar.isFromHome == 'homePage' && this.globalVar.filterSubjectsHomePage && this.globalVar.homeSelectedSubjDet) {
        if (this.subjectIntermediaryList[i].subjectID === this.globalVar.homeSelectedSubjDet.facetUri) {
          this.rootIntermediaryId = this.subjectIntermediaryList[i].intermediaryID;
          this.statementTabName = this.subjectIntermediaryList[i].intermediaryName;
          break;
        }
      } else {
        if (this.subjectIntermediaryList[i].subjectID === this.controllerScope?.intermediaryAlignData?.rootIntermediary.subjectCode) {
          this.rootIntermediaryId = this.subjectIntermediaryList[i].intermediaryID;
          this.statementTabName = this.subjectIntermediaryList[i].intermediaryName;
          break;
        }
      }
    }

    this.subjectlength = this.subjectBeanList.length;
    this.selectedList = {};
    if (this.globalVar.intermediaryPreSelectedDropdown.isProduct) {
      this.selectedSubject = this.globalVar.intermediaryPreSelectedDropdown.selectedList.prdSubject.subjectDesc;
    } else {
      this.selectedSubject = this.globalVar.intermediaryPreSelectedDropdown.selectedList.subject.subjectDesc;
    }
    for (var i = 0; i < this.subjectlength; i++) {
      if (this.subjectBeanList[i].subjectDesc === this.selectedSubject) {
        var subject = {};
        this.selectedList.subject = this.subjectBeanList[i];
        break;
      }
    }
    this.selectDiscipline();

  }

  public getModifiedFiltSubjHome = (filterSubjects: any) => {
    var locArr = [];
    for (let i = 0; i < filterSubjects.length; i++) {
      var locobj = {
        "subjectCode": filterSubjects[i].facetUri,
        "subjectDesc": filterSubjects[i].facetName
      }
      locArr.push(locobj);
    }
    return locArr;
  }

  public selectDiscipline = () => {
    var subjectIntermediaryList = this.globalVar.languageString.resources.subjectMappingsMetadata;
    this.globalVar.downloadUrl = undefined;
    var subjectMappingLength = subjectIntermediaryList.length;
    for (var i = 0; i < subjectMappingLength; i++) {
      if (subjectIntermediaryList[i].subjectID === this.selectedList.subject.subjectCode) {
        this.rootIntermediaryId = subjectIntermediaryList[i].intermediaryID;
        this.statementTabName = subjectIntermediaryList[i].intermediaryName;
        break;
      }
    }
    this.searchIntermediary(false);
  };

  public searchIntermediary = (infiniteScroll: any) => {
    if (!this.utilService.isEmpty(this.getCheckBoxParamValue("type", undefined))) {
      this.typeCheckBoxValues = "";
      var scheme = this.rootIntermediaryId;
      if (this.utilService.isEmpty(scheme)) {
        //////// scheme = 'http://schema.savvas.com/ns/intermediaryLiteracy';
      }
      console.log("scheme:::" + scheme)
      var url = 'api/intermediarystatement?scheme=' + scheme + '&pageSize=' + this.pagination.pageSize;
      if (infiniteScroll) {
        url = url + '&page=' + this.pagination.pageNum;
        this.statementLoading = true;
      } else {
        url = url + '&page=1';
        this.globalVar.loading = true;
        this.recordsUnavailable = false;
        this.pagination.totalRecords = undefined;
        this.pagination.totalRecords = 0;
      }
      if (!this.utilService.isEmpty(this.searchModel.text)) {
        var tempArr = [];
        if (!this.intermediaryCodeSearchEnabled) {
          //Changes for CMT-231 : Phrase search
          var textCopy = _.clone(this.searchModel.text);
          var phraseStart;
          var phraseEnd;
          var phrase;
          phraseStart = textCopy.indexOf('"');
          phraseEnd = textCopy.lastIndexOf('"');
          if (phraseStart >= 0 && phraseEnd >= 0) {
            phrase = textCopy.substring(phraseStart, phraseEnd + 1);
            if (!this.utilService.isEmpty(phrase.trim())) {
              textCopy = textCopy.replace(phrase, '');
              this.textParamValue.push(phrase.trim());
            }
          }
          //Changes end for CMT-231 : Phrase search
          tempArr = textCopy.split(" ");
          for (var tempArrIndex in tempArr) {
            if (!this.utilService.isEmpty(tempArr[tempArrIndex].trim())) {
              this.textParamValue.push(tempArr[tempArrIndex].trim());
            }
          }
        }
        if (this.intermediaryCodeSearchEnabled) {
          tempArr = this.searchModel.text.split(" ");
          for (var tempArrIndex in tempArr) {
            this.codeParamValue.push(tempArr[tempArrIndex].trim());
          }
        }
      }
      if (this.textParamValue.length > 0) {
        this.textParamValue = _.uniq(this.textParamValue);
        url = url + '&text=' + this.textParamValue.join(" ");
      }
      else {
        url = url + '&text=';
      }
      if (this.codeParamValue.length > 0) {
        this.codeParamValue = _.uniq(this.codeParamValue);
        url = url + '&code=' + this.codeParamValue.join(" ");
      }
      else {
        url = url + '&code=';
      }
      //Comma delimited string for type parameter
      let typeCheckBoxValues = this.typeCheckBoxValues;
      this.typeCheckBoxValues = this.getCheckBoxParamValue("type", typeCheckBoxValues);
      if (!this.utilService.isEmpty(this.typeCheckBoxValues)) {
        url = url + '&type=' + this.typeCheckBoxValues;
      }

      // //GET Request for the created url
      this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result: any) => {
        // if (result.total === 0) {
        //   this.confirmationPopup.showErrorMessage('No data found for the current searched criteria.');
        // }
        if (infiniteScroll) {
          _.each(result.item, (item) => {
            this.searchListItem.push(item);
          });
          this.statementLoading = false;
        } else {
          this.searchListItem = result.item;
          this.pagination.pageNum = result.page;
          this.pagination.totalRecords = result.total;
          // angular.element(infiniteScrollParentId).scrollTop(0);
        }
        this.pagination.pageNum = result.page;
        this.searchModel.text = undefined;
        this.globalVar.loading = false;
      }, (error) => {
        if (error.status === 500) {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.common.serverErrorNoDataMessage);
          this.globalVar.loading = false;
          this.statementLoading = false;
        }
      });
    } else {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.intermediaryAlignTab.statementMandatorySelectMessage);
    }
  }

  public loadMore = () => {
    if (this.pagination.totalRecords) {
      if (this.searchListItem.length < this.pagination.totalRecords - 1) {
        this.pagination.pageNum = this.pagination.pageNum + 1;
        this.searchIntermediary(true);
      } else if (this.pagination.pageNum > 1) {
        this.recordsUnavailable = true;
        return;
      }
    } else {
      this.searchIntermediary(false);
    }
    // Dynamic height adjust for intermediary statement 
    let vph = window.innerHeight;
    let element = document.getElementById('statement-data-tab');
    if (element) {
      element.style.height = vph + 'px';
    }
  };

  public removeSelectedText = (text: string) => {
    this.textParamValue = _.without(this.textParamValue, text)
    this.codeParamValue = _.without(this.codeParamValue, text);
    this.searchIntermediary(false);
  };

  public typeChecked = (node: any, e: any) => {
    if (node.checked) {
      node.checked = false;
    } else {
      node.checked = true;
    }
    e.stopImmediatePropagation();
  };

  public getIntermediaryTypeName = (typeArray: any) => {
    if (typeArray !== undefined) {
      var name;
      var type;
      var typeArrlength = typeArray.length;
      for (let i = 0; i < typeArrlength; i++) {
        type = typeArray[i];
        let applicationConstants = this.globalVar.applicationConstants;
        for (var key in applicationConstants) {
          if (applicationConstants.hasOwnProperty(key)) {
            for (var j = 0; j < this.searchModel.type.length; j++) {
              /////// Check area for possible errors f
              if (this.searchModel.type[j].type === key && type === applicationConstants[key]) {
                name = this.searchModel.type[j].shortValue;
                break;
              }
            }

          }
        }
      }
      return name;
    } else {
      return '';
    }
  };

  //Method to append type params in $http url
  public getCheckBoxParamValue = (checkboxType: any, checkBoxValues: any) => {
    let tempList: any = [];
    let applicationConstants = this.globalVar.applicationConstants;
    if (checkboxType === 'type') {
      tempList = this.searchModel.type;
    }
    let tempListLength = tempList.length;
    for (var i = 0; i < tempListLength; i++) {
      if (tempList[i].checked) {
        checkBoxValues = this.utilService.appendStrings(checkBoxValues, applicationConstants[tempList[i].schema]);
      }
    }
    return checkBoxValues;
  };

  //Method to trigger searchIntermediary function on hitting ENTER button
  public handleEnter = (event: any) => {
    if (event.keyCode == 13) {
      this.searchIntermediary(false);
    }
  };

  //Method to get name for statement tab
  public getStatementTabName = () => {
    var name;
    var subjectMappingLength = this.subjectIntermediaryList.length;
    for (var i = 0; i < subjectMappingLength; i++) {
      if (this.subjectIntermediaryList[i].subjectID === this.selectedList.prdsubject.subjectCode) {
        this.selectedList.rootIntermediary = this.subjectIntermediaryList[i].intermediaryID;
        break;
      }
    }
  };

  //Method to add dynamically type background color
  public getTypeBgColor = (typeArray: any) => {
    var style = '';
    var type = this.getIntermediaryTypeName(typeArray);
    if (type === 'STFk') {
      style = "intermediary-stfK-type";
    } else if (type === 'P') {
      style = "intermediary-P-type";
    } else if (type === 'CU') {
      style = "intermediary-C-type";
    }
    return style;
  };

  //Hide icon if statement exists in cart
  public hideAligned = (node: any) => {
    // node.mappedIntermediary = false;
    // if(this.controllerScope.knowledgeCartScope!==undefined){
    //   var cartData = this.controllerScope.knowledgeCartScope.knowledgeCartList;				
    //   if(cartData!==undefined && cartData.length > 0)
    //   {
    //     for(var i = 0; i<cartData.length ; i++) 
    //     {				
    //       if(cartData[i].id === node.id)
    //       {
    //         node.mappedIntermediary=true;	
    //         break;
    //       }
    //     }
    //   }
    // }									
    // return node.mappedIntermediary;
  };

  //Method to add statements to  cart
  public addStatementToCart = (intermediary: any) => {
    var selectedIntermediaryPrevious = false;
    if (!this.preloadData.treeAligned) {
      this.confirmationPopup.showErrorMessage('Please select a node and click align to proceed');
    }
    else {
      intermediary.addedToCart = true;
      if (this.knowledgeCartScope !== undefined) {
        if (this.newCartItemCount < 0) {
          this.newCartItemCount = 0;
        }
        var mappingItem = Object.assign(intermediary);
        mappingItem.newAdded = true;
        mappingItem.selected = true;
        this.preloadData.selectedTopicsList.forEach((item: any) => {
          if (item.data?.centrallyRelated.length > 1 && Array.isArray(item.data?.centrallyRelated)) {
            item.data?.centrallyRelated?.forEach((int: any) => {
              if (int.id === mappingItem.id) {
                selectedIntermediaryPrevious = true;
              }
            })
          }
        })
        this.preloadData.selectedTopicsList.forEach((item: any) => {
          if (item.data?.peripherallyRelated.length > 1 && Array.isArray(item.data?.peripherallyRelated)) {
            item.data?.peripherallyRelated?.forEach((int: any) => {
              if (int.id === mappingItem.id) {
                selectedIntermediaryPrevious = true;
              }
            })
          }
        })
        this.preloadData.selectedTopicsList.forEach((item: any) => {
          if (item.data?.keyRelated.length > 1 && Array.isArray(item.data?.keyRelated)) {
            item.data?.keyRelated?.forEach((int: any) => {
              if (int.id === mappingItem.id) {
                selectedIntermediaryPrevious = true;
              }
            })
          }
        })
        if (!selectedIntermediaryPrevious) {
          mappingItem.printDigitalAlignment = "printDigital";
          mappingItem.associationType = 'C';
          this.knowledgeCartScope.knowledgeCartList.push(Object.assign(mappingItem));
          this.newCartItemCount = this.newCartItemCount + 1;
          this.preloadData.cartCount.next(this.newCartItemCount);
          this.preloadData.knowledgeCartList.next(this.knowledgeCartScope.knowledgeCartList);
        }

        //this.preloadData.updateCartItemCount(this.newCartItemCount);
        //this.preloadData.cartCountFunction(this.newCartItemCount);

        if (!this.knowledgeCartDirty) {
          this.knowledgeCartDirty = true;
        }
      }
    }
  };

  //Method to clear all searched texts
  public clearSearchedTexts = () => {
    this.textParamValue = [];
    this.codeParamValue = [];
    this.searchModel.text = undefined;
    this.searchIntermediary(false);
  };

  //Method to check if intermediary code needs to be displayed 
  public showIntermediaryCode = (obj: any) => {
    var show = false;
    if (!_.isEmpty(obj) && !this.utilService.isEmpty(obj.en.trim())) {
      show = true;
    }
    return show;
  };

  public exportIntermediary = () => {
    let subject = this.selectedList.subject.subjectCode;
    if (subject) {
      this.globalVar.generatingXLS = true;
      let subArray = subject.split('#');
      let url = 'api/intermediary/' + subArray[1] + '/export';
      const headers: any = {
        responseType: 'text'
      };
      this.httpRestClient.getForUrl(url, headers, undefined).subscribe((result: any) => {
        this.globalVar.generatingXLS = false;
        if (result === "") {
          this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.manageUploadIntermediaryExportNoDataMessage);
          this.globalVar.downloadUrl = undefined;
        } else {
          this.globalVar.downloadUrl = result;
        }
      }, (error) => {
        this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.manageUploadExcelExportFailureMessage);
      })
    } else {
      this.confirmationPopup.showErrorMessage(this.globalVar.languageString.resources.manageTab.manageUploadIntermediarySubjectMandatory);
    }
  }

  public downloadFile = () => {
    let url = 'api/intermediary/export/' + this.globalVar?.downloadUrl;
    //let locUrl = window.location.href.split('cm-web')[0] + 'cm-web/';
    let locUrl = '';
    window.open(locUrl + url);
  }

  onKeydown(event:any)
  {
    if(event.key=='Enter')
      {
        this.searchIntermediary(false);
      }
  }

}
