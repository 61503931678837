import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatSortModule} from '@angular/material/sort';

import { LoginComponent } from './components/login/login.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { WarningPopupComponent } from './components/warning-popup/warning-popup.component';
import { ConfirmationPopupService } from '../app/services/confirmation-popup/confirmation-popup.service';
import { GlobalVariablesService } from '../app/services/global-variables/global-variables.service';
import { ViewListComponent } from './components/view-list/view-list.component';
import { FormsModule } from '@angular/forms';
import { ManagePageComponent } from './components/manage-page/manage-page.component';
import { CurriculumMenuComponent } from './components/curriculum-menu/curriculum-menu.component';
import { IntermediaryStatementsComponent } from './components/intermediary-statements/intermediary-statements.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { UploadCurriculumComponent } from './components/upload-curriculum/upload-curriculum.component';
import { OrderModule } from 'ngx-order-pipe';
import { UpdateCurriculumComponent } from './components/update-curriculum/update-curriculum.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatTreeModule } from '@angular/material/tree';
// import { MatIconModule } from '@angular/material/icon';
import { ManageAuthorityComponent } from './components/manage-authority/manage-authority.component';
import { AuthorityTreeComponent } from './components/authority-tree/authority-tree.component';
import { AddAuthorityCurriculumSetComponent } from './components/add-authority-curriculum-set/add-authority-curriculum-set.component';
import { UploadDownloadProductComponent } from './components/upload-download-product/upload-download-product.component';
import { ManageProductComponent } from './components/manage-product/manage-product.component';
import { ManageProductTreeComponent } from './components/manage-product-tree/manage-product-tree.component';
import { AddEditProductPopupComponent } from './components/add-edit-product-popup/add-edit-product-popup.component';
import { UploadIntermediaryComponent } from './components/upload-intermediary/upload-intermediary.component';
import { EditCurriculumGradeComponent } from './components/edit-curriculum-grade/edit-curriculum-grade.component';
import { EditLocalGradeComponent } from './components/edit-local-grade/edit-local-grade.component';
import { EditTopicComponent } from './components/edit-topic/edit-topic.component';
import { TopicTreeViewComponent } from './components/topic-tree-view/topic-tree-view.component';
import { AddEditTopicPopupComponent } from './popups/add-edit-topic-popup/add-edit-topic-popup.component';
import { SubstitutionSetComponent } from './components/substitution-set/substitution-set.component';
import { EditSubsPopupComponent } from './popups/edit-subs-popup/edit-subs-popup.component';
import { ViewCurriculumComponent } from './components/view-curriculum/view-curriculum.component';
import { ArrayRangePipe } from './services/pipe/array-range.pipe';
import { IntermediaryAlignHomeComponent } from './components/intermediary-align-home/intermediary-align-home.component';
import { IntermediaryAlignmentComponent } from './components/intermediary-alignment/intermediary-alignment.component';
import { LearningObjectiveTreeComponent } from './components/learning-objective-tree/learning-objective-tree.component';
import { CorrelationLeftSideComponent } from './components/correlation/correlation-left-side/correlation-left-side.component';
import { CorrelationHomeComponent } from './components/correlation/correlation-home.component';
import { CorrelationRightSideComponent } from './components/correlation/correlation-right-side/correlation-right-side.component';
import { CorrelationSaveComponent } from './components/correlation/correlation-save/correlation-save.component';
import { CorrelationService } from './services/correlation/correlation.service';
import { CorrelationList } from './components/correlation/correlation-list/correlation-list.component';
import { CorrelationAllignComponent } from './components/correlation/correlation-allign/correlation-allign.component';
import { StandardUriLookup } from './components/standards-uri-lookup/standard-uri-lookup.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { AuthModule } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { NgidlekeepaliveComponent } from './components/ngidlekeepalive/ngidlekeepalive.component';
import { environment } from 'src/environments/environment';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UnauthorizedComponent,
    HomePageComponent,
    WarningPopupComponent,
    ViewListComponent,
    ManagePageComponent,
    CurriculumMenuComponent,
    IntermediaryStatementsComponent,
    UploaderComponent,
    UploadCurriculumComponent,
    UpdateCurriculumComponent,
    ManageAuthorityComponent,
    AuthorityTreeComponent,
    AddAuthorityCurriculumSetComponent,
    UploadDownloadProductComponent,
    ManageProductComponent,
    ManageProductTreeComponent,
    AddEditProductPopupComponent,
    UploadIntermediaryComponent,
    EditCurriculumGradeComponent,
    EditLocalGradeComponent,
    EditTopicComponent,
    TopicTreeViewComponent,
    AddEditTopicPopupComponent,
    SubstitutionSetComponent,
    EditSubsPopupComponent,
    ViewCurriculumComponent,
    ArrayRangePipe,
    IntermediaryAlignHomeComponent,
    CorrelationLeftSideComponent,
    IntermediaryAlignmentComponent,
    LearningObjectiveTreeComponent,
    CorrelationHomeComponent,
    CorrelationRightSideComponent,
    CorrelationSaveComponent,
    CorrelationList,
    CorrelationAllignComponent,
    StandardUriLookup,
    NgidlekeepaliveComponent 
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    FormsModule,
    InfiniteScrollModule,
    OrderModule,
    BrowserAnimationsModule,
    RichTextEditorAllModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatPaginatorModule,
    MatCheckboxModule,
    AuthModule.forRoot({
      domain: environment.auth.domain,
      clientId: environment.auth.clientId,
      authorizationParams: {
        redirect_uri: environment.auth.redirect_uri,
        cacheLocation: environment.cacheLocation
      }
    })
  ],
  providers: [
    GlobalVariablesService,
    ConfirmationPopupService,
    CorrelationService,
    EditTopicComponent,
    BsModalService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
