<div class="loader-container" [hidden]="!globalVar?.loading">
    <div class="loading-overlay"></div>
    <div class="loading-icon">
        <img src="assets/images/loading_100x100.gif" />
    </div>
</div>
<div class="headerPanel headerPanelMble" data-ng-hide="showMaximizeBrowseScreen || showMaximizeAlignmentScreen">
    <span class="lSideBar" (click)="globalVar?.lSideBarClick($event);"><i class="fa fa-bars"
            aria-hidden="true"></i></span>
    <a class="mbleLogoPanel" (click)="goToView()">
        <img src="assets/images/Savvas_Logo.png" class="headerLogo">
        <span class="brandNameDesign">Curriculum Management Tool</span>
    </a>
    <div class="rSideDot" (click)="globalVar?.rSideDotClick($event);">
        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
    </div>
    <div class="menuPanel">
        <div>
            <nav class="menuPanelLeft" (click)="$event.preventDefault()">
                <a class="menuPanelItem" [ngClass]="{ 'active' : isActiveMainMenu('view')}"
                    (click)="goToView()">Home</a>
                <a class="menuPanelItem" [ngClass]="{ 'active': isActiveMainMenu('manage')}" (click)="goToManage()"
                    *ngIf="hasUser() && isUserAdmin">Manage</a>
                <a class="menuPanelItem" [ngClass]="{ 'active': isActiveMainMenu('align')}" (click)="goToAlignTab()"
                *ngIf="hasUser() && (isUserAdmin || isUserCorrelator)">Intermediary Align</a>
                <a class="menuPanelItem" [ngClass]="{ 'active': isActiveMainMenu('correlation')}"
                    (click)="goToCorrelationTab()" *ngIf="hasUser()">Correlations</a>
            </nav>
        </div>
        <div class="loginPanel" style="margin-right: 50px;">
            <nav (click)="$event.preventDefault()">
                <div [hidden]="!hasUser()">                 
                    <span class="loggedInUserName"
                        [hidden]="globalVar.userProfile">{{globalVar?.languageString?.resources?.menu?.menuWelcomeUserLabel}}
                        {{ globalVar?.userProfile?.name }}</span>
                        <img *ngIf="globalVar?.userProfile?.picture" [src]="globalVar?.userProfile?.picture" alt="User Picture" class="user-picture">
                
                    <a class="menuPanelItem" id="logoutButton"
                        (click)="openModal(logOutPopupTemp)">{{globalVar?.languageString?.resources?.menu?.menuLogoutLabel}}</a>
                </div>
                <div [hidden]="hasUser()">
                    <a class="menuPanelItem" id="loginButton"
                        (click)="showLoginPopup()">{{globalVar?.languageString?.resources?.menu?.menuLoginLabel}}</a>
                </div>
            </nav>
        </div>
        <div class="loginPanel">
            <a class="menuPanelItem" style="float: right;" [hidden]="!helpUrl" href="#" href="{{ helpUrl }}"
                target="_blank" title="{{ helpUrl }}">{{globalVar?.languageString?.resources?.menu?.menuHelpLabel}}</a>
        </div>
    </div>
    <nav class="menuPanelLeftMble displayNoneMble" id="menuPanelLeftMble" data-ng-click="$event.preventDefault()">
        <a class="menuPanelItem" [ngClass]="{ 'active' : isActiveMainMenu('view')}"
            (click)="goToView()"><span>Home</span></a>
        <a class="menuPanelItem" [ngClass]="{ 'active': isActiveMainMenu('manage')}" (click)="goToManage()"
        *ngIf="hasUser() && isUserAdmin"><span>Manage</span></a>
        <a class="menuPanelItem" [ngClass]="{ 'active': isActiveMainMenu('align')}" (click)="goToAlignTab()"
        *ngIf="hasUser() && (isUserAdmin || isUserCorrelator)"><span>Intermediary Align</span></a>
        <a class="menuPanelItem" [ngClass]="{ 'active': isActiveMainMenu('correlation')}" (click)="goToCorrelationTab()"
        *ngIf="hasUser()"><span>Correlations</span></a>
    </nav>
    <div class="loginPanel loginPanelMble displayNoneMble">
        <nav class="navRightMenu" (click)="$event.preventDefault()">
            <div class="navMenuItems" [hidden]="!hasUser()">
                                <span class="loggedInUserName"
                    [hidden]="!globalVar?.userProfile">{{globalVar?.languageString?.resources?.menu?.menuWelcomeUserLabel}}
                    {{ globalVar?.userProfile?.userName }}</span>
            </div>
            <div class="navMenuItems" [hidden]="!hasUser()">
                <a class="menuPanelItem" id="logoutButton"
                    (click)="openModal(logOutPopupTemp)">{{globalVar?.languageString?.resources?.menu?.menuLogoutLabel}}</a>
            </div>
            <div class="navMenuItems" [hidden]="hasUser()">
                <a class="menuPanelItem" id="loginButton"
                    (click)="showLoginPopup()">{{globalVar?.languageString?.resources?.menu?.menuLoginLabel}}</a>
            </div>
            <div class="navMenuItems">
                <a class="menuPanelItem" style="float: right;" [hidden]="!helpUrl" href="#" href="{{ helpUrl }}"
                    target="_blank"
                    title="{{ helpUrl }}">{{globalVar?.languageString?.resources?.menu?.menuHelpLabel}}</a>
            </div>
        </nav>
    </div>
</div>

<ng-template #logOutPopupTemp>
    <app-warning-popup [DoFunctionOnSuccess]="logout"
        [PopupMessage]="globalVar?.languageString?.resources?.homeTab?.loginPopupLogoutMessage"
        [ConfirmationMessage]="'User ' + globalVar?.userProfile?.userName + globalVar?.languageString?.resources?.homeTab?.loginPopupSuccessLogoutMessage"
        [ButtonLabel]="'Log out'" [modalRef]="modalRef"></app-warning-popup>
</ng-template>
<ng-template #checkUnsavedEditsTemp>
    <app-warning-popup [DoFunctionOnSuccess]="globalVar?.checkUnsavedEditsTemp?.popupCallBack"
        [PopupMessage]="globalVar?.checkUnsavedEditsTemp?.popupMessage"
        [ConfirmationMessage]="globalVar?.checkUnsavedEditsTemp?.popupMessage"
        [ButtonLabel]="globalVar?.checkUnsavedEditsTemp?.buttonLabel"
        [modalRef]="globalVar?.checkUnsavedEditsTemp?.modalRef"></app-warning-popup>
</ng-template>

<div class="mainPanel">
    <div id="confirmationPopup" class="alert alert-success animation" [hidden]="!confirmationPopup.successMessage">
        {{confirmationPopup.successMessage}}</div>
    <div id="errorPopup" class="alert alert-error animation" [hidden]="!confirmationPopup.errorMessage">
        {{confirmationPopup.errorMessage}}</div>
    <!-- Footer section -->
    <div class="applicationFooter">
        <!-- {{applicationCache.languageString.resources.menu.footerBuildVersionLabel}} {{ buildVersion }}. -->
        Copyrights © {{year}} Savvas Learning Company LLC. All rights reserved. v{{ buildVersion }}
    </div>
</div>