import { Component } from '@angular/core';
import { GlobalVariablesService } from './services/global-variables/global-variables.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cm-web';
  public loading: any;

  constructor(
    public globalVar : GlobalVariablesService
  ){}

  ngDoCheck(): void {
    this.loading = this.globalVar.loading;
  }

}
