import { ChangeDetectorRef, Component } from '@angular/core';
import { GlobalVariablesService } from 'src/app/services/global-variables/global-variables.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AuthService } from '@auth0/auth0-angular';
@Component({
  selector: 'app-ngidlekeepalive',
  templateUrl: './ngidlekeepalive.component.html',
  styleUrls: ['./ngidlekeepalive.component.scss']
})
export class NgidlekeepaliveComponent {
  idleState: string = "Not started";
  constructor(private idle: Idle, private cd: ChangeDetectorRef,  private auth: AuthService, public globalVar: GlobalVariablesService,
   ) {
    idle.setIdle(10800);
    idle.setTimeout(3600);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      console.log('session started')
      this.idleState = "Started";
      cd.detectChanges();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "Idle";
    })

    idle.onTimeout.subscribe(() => {
      this.idleState = "Timedout";
      this.globalVar.userProfile = undefined;
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      localStorage.removeItem('isAuthenticated');
      this.auth.logout();
       
    })
  }

  ngOnInit(): void {
    this.setStates();
  }

  setStates() {
    this.idle.watch();
    this.idleState = "Started";
  }

}
