import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GlobalVariablesService } from '../../services/global-variables/global-variables.service';
import { ApplicationLoaderService } from '../../services/common/application-loader.service';
import { ConfirmationPopupService } from '../../services/confirmation-popup/confirmation-popup.service'
import { HttpRestClientService } from '../../services/common/http-rest-client.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // public loading = true;
  public isUserAdmin: any = undefined;
  public userAction = 0;  // some ENUM for logged user: 0 - null, 1 - loading request send, 2 - loaded and logged in, 3 - loaded and not logged in
  public selectedTheme: any = 'savvasDarkBlue';
  public isUserCorrelator = false;
  public helpUrl: any;
  public buildVersion: any;
  public buildTime: any;
  public password: any;
  public knowledgeCartDirty: any;
  public year = (new Date()).getFullYear();
  // public languageString:any;
  public errorMessage: any;
  public successMessage: any;
  public authApi = environment.auth.authApi;
  public isAuthenticated: boolean = false;
  // public activeMainMenu:any = "view";
  public modalRef: BsModalRef | undefined;
  @ViewChild('checkUnsavedEditsTemp') private checkUnsavedEditsTemp: TemplateRef<any> | undefined;
  @ViewChild('statusChangePopupTemp') private statusChangePopupTemp: TemplateRef<any> | undefined;

  constructor(
    private appLoadService: ApplicationLoaderService,
    public globalVar: GlobalVariablesService,
    private httpRestClient: HttpRestClientService,
    // private httpClient: HttpClient,
    private route: Router,
    private modalService: BsModalService,
    public confirmationPopup: ConfirmationPopupService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.globalVar.activeMainMenu = 'manage';
    this.appLoadService.initializeLanguageStringsCache(undefined);
    this.appLoadService.currentApprovalStageMessage.subscribe((result: any) => {
      this.globalVar.languageString = result;
    });
    let self = this;

    console.log(this.globalVar.userProfile);

    const storedAuthState = localStorage.getItem('isAuthenticated');
    if (storedAuthState === null) {
      this.isAuthenticated = false;
    }else{
      this.isAuthenticated = storedAuthState === 'true' ? true : false;
    }
    console.log(this.isAuthenticated, "this.isAuthenticated");

    if (this.isAuthenticated) {
      // User is authenticated, perform your logic here
      this.loadUser(() => {
        this.loadHelpUrl(() => {
          this.loadBuildNumber(undefined);
        });
      }, true);
      this.showLoginPopupWhileLoading();
    } else {
      console.log("ELSE");
      this.auth.isAuthenticated$.subscribe((isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;
        if (this.isAuthenticated) {
console.log("else this.isAuthenticated", this.isAuthenticated);

          this.auth.idTokenClaims$.subscribe((token) => {
            if (token) {
              console.log(token, "token::::::");
              localStorage.setItem('currentUser', JSON.stringify(token));
              localStorage.setItem('isAuthenticated', 'true');
              //this.userType = this.dataservice.userType;
              console.log(token, "token");

              this.loadUser(() => {
                this.loadHelpUrl(() => {
                  this.loadBuildNumber(undefined);
                });
              }, true);
              this.showLoginPopupWhileLoading();

            }
          });


        } else {
          this.authenticateUser();
        }
      });
    }




  }


  b64EncodeUnicode(str: any) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode(p1);
      }));
  }

  openModal = (template: TemplateRef<any>) => {
    this.modalRef = this.modalService.show(template);
  }

  alerttemp = () => {
    alert();
  }

  loadUser(callback: any, showLoading: any): void {
    if (this.isAuthenticated) {
      console.log("loadUser");
      this.globalVar.loading = showLoading;
                this.isUserAdmin = false;
          this.isUserCorrelator = false;
          this.selectedTheme = "savvasDarkBlue";
      this.userAction = 1;
      this.auth.user$.subscribe((profile) => {
        console.log(profile, "profile-loadUser");
        if (profile && profile.name && profile.name.length > 0) {
          this.globalVar.userProfile = profile;
        } else if (localStorage['currentUser'] != null) {
          this.globalVar.userProfile = JSON.parse(localStorage['currentUser']);
        }
        if (this.globalVar.userProfile) {          
          this.userAction = 2;
          const localStgKey = this.globalVar.userProfile.email.split('.').join('') + 'SelectedTheme';
          if (localStorage.getItem(localStgKey)) {
            this.selectedTheme = localStorage.getItem(localStgKey);
          } else {
            this.selectedTheme = "savvasDarkBlue";
          }
          if(!this.globalVar.userProfile.roles){
            this.httpRestClient.getForUrl('api/admin/getUserProfile', undefined, undefined).subscribe((result) => {
              if (result && this.globalVar.userProfile) {
                this.globalVar.userProfile.roles = result.roles;     
             console.log('result', result);
              } 
              this.loadIsUserAdmin();
              this.loadIsCorrelator();
            }, (error) => {
              this.loadIsUserAdmin();
              this.loadIsCorrelator();
              console.error('error in loadUser method login component');
            });
           
          }

       
        }

      });

      this.globalVar.loading = false;
         if (callback) {
           callback();
        }

    }

  }

  // loadUser(callback: any, showLoading: any): void {
  //   if (this.userAction == 0) {
  //     this.globalVar.loading = showLoading;
  //     this.userAction = 1;
  //     this.httpRestClient.getForUrl('api/user/currentUserProfile', undefined, undefined).subscribe((result) => {
  //       if (result && result.userName && result.userName.length > 0) {
  //         this.globalVar.userProfile = result;
  //         this.loadIsUserAdmin();
  //         this.loadIsCorrelator();
  //         this.userAction = 2;
  //         var localStgKey = this.globalVar.userProfile?.userMail?.split('.').join('') + 'SelectedTheme';
  //         if (localStorage.getItem(localStgKey)) {
  //           this.selectedTheme = localStorage.getItem(localStgKey);
  //         } else {
  //           this.selectedTheme = "savvasDarkBlue";
  //         }
  //       } else {
  //         this.globalVar.userProfile = undefined;
  //         this.isUserAdmin = false;
  //         this.isUserCorrelator = false;
  //         this.userAction = 3;
  //         this.selectedTheme = "savvasDarkBlue";
  //       }
  //       this.globalVar.loading = false;
  //       if (callback) {
  //         callback();
  //       }
  //     }, (error) => {
  //       console.error('error in loadUser method login component')
  //     });
  //   } else if (callback) {
  //     callback();
  //   }
  // }

  loadHelpUrl(callback: any) {
    var url = "api/application/helpUrl";
    const headers: any = {
      responseType: 'text'
    };
    if (!this.helpUrl) {
      this.httpRestClient.getForUrl(url, headers, undefined).subscribe((result) => {
        this.helpUrl = result;
        console.log(this.helpUrl);
        this.globalVar.loading = false;
        if (callback) {
          callback();
        }
      }, (error) => {
        console.error('error in loadHelpUrl method login component')
      });
    }
  }

  loadBuildNumber(callback: any) {
    var url = "api/application/version";
    if (!this.buildVersion || !this.buildTime) {
      this.globalVar.loading = true;
      this.buildVersion = undefined;
      this.buildTime = undefined;
      this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
        if (result && result.length == 2) {
          console.log(result);
          this.buildVersion = result[0];
          this.buildTime = result[1];
          this.globalVar.loading = false;
        }
        if (callback) {
          callback();
        }
      }, (error) => {
        console.error('error in loadBuildNumber method login component')
      });
    }
  }

  authenticateUser() {
    this.auth.loginWithRedirect();
    // var url = "api/user/authenticateUserProfile";
    // const headers: any = {
    //   responseType: 'text'
    // };
    // this.httpRestClient.getForUrl(url, headers, undefined).subscribe((result) => {
    //   if (result != null && result != '') {
    //      window.location.href = result;
    //   }
    // }, (error) => {
    //   console.error('error in authenticat user method login component')
    // });
  }

  loadIsUserAdmin() {
    if(this.globalVar.userProfile && this.globalVar.userProfile.name &&
      this.globalVar.userProfile.roles && this.globalVar.userProfile.roles.length > 0 &&
      this.globalVar.userProfile.roles.indexOf('ROLE_ADMIN') !== -1){
    this.isUserAdmin = true;
    console.log('isUserAdmin', this.isUserAdmin);
      }
  }

  loadIsCorrelator() {
    if( this.globalVar.userProfile && this.globalVar.userProfile.name &&
      this.globalVar.userProfile.roles && this.globalVar.userProfile.roles.length > 0 &&
      this.globalVar.userProfile.roles.indexOf('ROLE_CORRELATOR') !== -1){
    this.isUserCorrelator = true;
    console.log('isUserCorrelator', this.isUserCorrelator);
      }
  }

  isActiveMainMenu(menuItem: string) {
    if (this.globalVar.activeMainMenu == undefined) {
      this.globalVar.activeMainMenu = 'browse';
    }
    return this.globalVar.activeMainMenu == menuItem;
  };

  showError() {
    this.globalVar.loading = false;
    this.password = '';
    this.userAction = 3;
  }

  showLoginPopupWhileLoading() {
    this.globalVar.loading = true;
    this.verifyUserCredential();
  };

  showLoginPopup() {
    this.verifyUserCredential();
  };

  hasUser = () => {
    if (this.userAction == 2) {
      this.globalVar.hasUser = true;
      return true;
    } else if (this.userAction == 3) {
      this.globalVar.hasUser = false;
      return false;
    } else {
      this.globalVar.hasUser = false;
      return false;
    }
  };

  verifyUserCredential() {
    // var url = "api/user/getAuthenticateUserProfile";
    // this.httpRestClient.getForUrl(url, undefined, undefined).subscribe((result) => {
    const result = this.globalVar.userProfile;
    if (!this.isAuthenticated) {
      this.authenticateUser();
    } else {
      if (this.globalVar.userProfile) {
        this.globalVar.userProfile = result;
        this.loadIsUserAdmin();
        this.loadIsCorrelator();
        this.userAction = 2;
        var localStgKey = this.globalVar.userProfile.email.split('.').join('') + 'SelectedTheme';
        if (localStorage.getItem(localStgKey)) {
          this.selectedTheme = localStorage.getItem(localStgKey);
        } else {
          this.selectedTheme = "savvasDarkBlue";
        }
      } else {
        this.globalVar.userProfile = undefined;
        this.isUserAdmin = false;
        this.isUserCorrelator = false;
        this.userAction = 3;
        this.selectedTheme = "savvasDarkBlue";
      }
      this.globalVar.loading = false;
    }
    // });
  }

  logout = () => {
    this.checkUnsavedEdits(() => {
      console.log('logout');
      this.globalVar.userProfile = undefined;
      this.isUserAdmin = false;
      this.userAction = 3;
      // $templateCache.removeAll();
      this.selectedTheme = "savvasDarkBlue";
      var url = 'logout';
      localStorage.removeItem('currentUser');
      localStorage.removeItem('isAuthenticated');
      this.auth.logout();
     // this.goToLogin();
      // this.httpRestClient.postForUrl(url, undefined, undefined).subscribe((result) => {
      //   this.goToLogin();
      // }, (error) => {
      //   this.goToLogin();
      // });
    });
  };

  goToLogin() {


    this.auth.loginWithRedirect();
    // console.log(window.location);
    // var url = 'api/user/getLogoutURL';
    // const headers: any = {
    //   responseType: 'text'
    // };
    //   this.httpRestClient.getForUrl(url, headers, undefined).subscribe((result) => {
    //     window.location.href = result;
    //   },(error) => {
    //     console.log("Logout failed")
    //   });
  }

  //Method to check unsaved edits and show confirmation pop up.
  checkUnsavedEdits(callback: any) {
    console.log("getting called checkunsaved");
    if (this.globalVar.knowledgeCartDirty) {
      this.modalRef = this.modalService.show(this.checkUnsavedEditsTemp ? this.checkUnsavedEditsTemp : '');
      this.globalVar.checkUnsavedEditsTemp.PopupMessage = this.globalVar.languageString.resources.common.unsavedEditPopUpMessage;
      this.globalVar.checkUnsavedEditsTemp.buttonLabel = this.globalVar.languageString.resources.others.yesLabel;
      this.globalVar.checkUnsavedEditsTemp.popupCallBack = () => {
        if (callback) {
          callback();
          //Updating globalVar dirty check
          this.globalVar.knowledgeCartDirty = false;
        }
      };
    }
    else {
      callback();
    }
  };

  goToView() {
    var self = this;
    this.checkUnsavedEdits(function () {
      self.globalVar.activeMainMenu = 'view';
      self.route.navigate(['/view/approved']);
    });
  }

  goToManage() {
    var self = this;
    this.checkUnsavedEdits(function () {
      self.globalVar.activeMainMenu = 'manage';
      self.route.navigate(['/curriculum/list/new']);
    });
  }

  goToAlignTab() {
    var self = this;
    this.checkUnsavedEdits(function () {
      self.globalVar.activeMainMenu = 'align';
      self.route.navigate(['intermediary/align/home']);
    });
  }

  goToCorrelationTab() {
    var self = this;
    this.checkUnsavedEdits(function () {
      self.globalVar.activeMainMenu = 'correlation';
      self.route.navigate(['correlation']);
    });
  }

  goToCurriculumPreview = (curriculumID: any) => {
    this.checkUnsavedEdits(() => {
      this.globalVar.activeMainMenu = 'manage';
      this.route.navigate(["/curriculum/" + curriculumID]);
    });
  };

}
